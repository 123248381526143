<template>
  <div>
    <el-form ref="editForm" :model="form" label-width="280px" :rules="editFormRules">
      <el-form-item label="新增二级代理商可开会员最低数量" prop="totalAccountNum" style="width: 500px" >
        <el-input-number v-model="form.totalAccountNum"  label-width="80px"  :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="新增二级代理商最低时长" prop="rd" style="width: 500px" >
        <el-input-number v-model="form.rd"  label-width="80px"  :min="0"></el-input-number>
      </el-form-item>
      

      <el-form-item>
        <el-button type="primary" @click.prevent="onSubmit">确定</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AgentInit",
  data() {
    return {
      
      dialogVisible: true,
      
      form: {
        totalAccountNum:0,
        rd:0,
      },
      editFormRules: {
					totalAccountNum: [
						{required: true, message: '不能为空', trigger: 'blur'},
            {pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					],
          rd: [
						{required: true, message: '不能为空', trigger: 'blur'},
            {pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					]
			},
    };
  },
  created() {
    this.getInitInfo()
  },

  methods: {
    
    


    //提交表单
    onSubmit(){
      this.$refs['editForm'].validate((valid) => {
					if (valid) {
						this.$axios.post('/sys/agent/update/initInfo' , this.form)
							.then(res => {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getInitInfo()
									}
								});
							})
					} else {
						return false;
					}
				});
    },

    //页面刷新-初始化信息
    getInitInfo() {
      this.$axios.get("/sys/agent/get/initInfo").then((res) => {
       
        console.log("res.data",res.data)
        this.form.totalAccountNum = res.data.data.totalAccountNum;    
        this.form.rd = res.data.data.rd
        
        //宣传图
        // if (tmp.image != undefined&&tmp.image !=""&&tmp.image !=this.defaultImg) {
        //   //首页图-显示之前上传的图片
        //   this.image = tmp.image;
        //   //“点击上传” --> "重新上传"
        //   this.image_button_name = "重新上传";
        //   //大图预览
        //   this.imagesList=[this.image]
        // }

      });
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
.el-select-dropdown__item.selected {
  color: #606266;
  font-weight: 100;
}
</style>